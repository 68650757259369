/* global gsap */
window.addEventListener('DOMContentLoaded', () => {
  // user scripts

  const elemAminScale = document.querySelectorAll('[data-anim-scale]');
  const elemAminRotate = document.querySelectorAll('[data-anim-rotate]');
  const elemAnimSlideX = document.querySelectorAll('[data-anim-slide-x]');
  const elemAnimSlideY = document.querySelectorAll('[data-anim-slide-y]');

  if (elemAminScale.length) {
    elemAminScale.forEach((elem) => {
      gsap.from(elem, {
        scrollTrigger: {
          trigger: elem.parentElement,
          start: '25% 90%',
          end: () => '+=' + elem.parentElement.offsetHeight / 1.5,
          scrub: true,
        },
        scale: elem.dataset.animScale,
        ease: 'none',
      });
    });
  }

  if (elemAminRotate.length) {
    elemAminRotate.forEach((elem) => {
      gsap.to(elem, {
        scrollTrigger: {
          trigger: elem.parentElement,
          start: '0% 95%',
          end: '100% 5%',
          scrub: true,
        },
        rotation: elem.dataset.animRotate,
        ease: 'none',
      });
    });
  }

  if (elemAnimSlideX.length) {
    elemAnimSlideX.forEach((elem) => {
      gsap.from(elem, {
        scrollTrigger: {
          trigger: elem.parentElement,
          start: '-25% 50%',
          end: () => '+=' + elem.parentElement.offsetHeight / 1.5,
          scrub: true,
        },
        x: elem.dataset.animSlideX,
        ease: 'none',
      });
    });
  }

  if (elemAnimSlideY.length) {
    elemAnimSlideY.forEach((elem) => {
      gsap.from(elem, {
        scrollTrigger: {
          trigger: elem.parentElement,
          start: '-25% 75%',
          end: () => '+=' + elem.parentElement.offsetHeight,
          scrub: true,
        },
        y: elem.dataset.animSlideY,
        ease: 'none',
      });
    });
  }
});
